import * as React from "react"
import { graphql } from "gatsby"
import { Link, Trans } from "gatsby-plugin-react-i18next"
import Seo from "../components/SEO"
import BlogItem from "../components/BlogItem"
import { PagerStyles } from "../styles/JournalStyles"
import Banner from "../components/Banner"
import Layout from "../components/Layout"
import { useTranslation } from "react-i18next"

const PostsTemplate = (props) => {
	const { edges } = props.data.allMarkdownRemark
	const { siteMetadata } = props.data.site

	const { currentPage, numPages } = props.pageContext
	const isFirst = currentPage === 1
	const isLast = currentPage === numPages
	const prevPage = currentPage - 1 === 1 ? "" : (currentPage - 1).toString()
	const nextPage = (currentPage + 1).toString()
	const { t } = useTranslation()

	return (
		<>
			<Seo title={siteMetadata.title} />
			<Layout>
				<Banner content={`${t("Posts")} - ${siteMetadata.title}`} />
				<p>
					{" "}
					{siteMetadata.description}
				</p>
				{edges.map(({ node }, index) => {
					return <BlogItem key={index} nodeObj={node} index={index} />
				})}
				{/* Paging controls
        If there are multiple pages, show pager */}
				{numPages > 1 && (
					<PagerStyles>
						<div className="btns">
							{!isFirst && (
								<Link to={`/posts/${prevPage}`} rel="prev">
									<Trans>Previous</Trans>
								</Link>
							)}
							{!isLast && (
								<Link to={`/posts/${nextPage}`} rel="next">
									<Trans>Next</Trans>
								</Link>
							)}
						</div>
						<div className="numbers">
							{Array.from({ length: numPages }, (_, i) => (
								<Link
									key={`pagination-numbers${i + 1}`}
									to={`/posts/${i === 0 ? "" : i + 1}`}
								>
									{i + 1}
								</Link>
							))}
						</div>
					</PagerStyles>
				)}
			</Layout>
		</>
	)
}

export default PostsTemplate

export const postsQuery = graphql`
	query postsQuery($language: String!, $skip: Int!, $limit: Int!) {
		allMarkdownRemark(
			filter: { frontmatter: { locale: { eq: $language } } }
			sort: { fields: [frontmatter___date], order: DESC }
			limit: $limit
			skip: $skip
		) {
			edges {
				node {
					frontmatter {
						title
						date(formatString: "MMMM DD, YY")
						slug
						tags
						featuredImage {
							childImageSharp {
								gatsbyImageData(layout: FULL_WIDTH)
							}
						}
					}
					excerpt
				}
			}
		}

		locales: allLocale(filter: {language: {eq: $language}}) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}

		site {
			siteMetadata {
				title
				description
			}
		}
	}
`
